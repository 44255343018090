<template>
  <div>
    <Header></Header>
    <div v-if="isRight">
        <el-container  class="container">
            <el-aside width="200px" class="container-left">
                <el-menu
                :default-active="selMenu"
                >
                <!--使用v-if语句进行判断有无children菜单，确定一级菜单是否需要下拉功能-->
                <template v-for="item in menuList">
                <el-submenu
                    text-color="#fff"
                    :index="item.name"
                    :key="item.name"
                    v-if="item.children?.length>0">
                    <!--一级菜单在submenu中为下拉状态-->
                    <template #title>
                    <i  class="iconfont i_icon" :class="item.icon"></i>
                    <span>{{item.meta.title}}</span>
                    </template>
                    <!--二级菜单再次进行v-if判断是否显示二级菜单-->
                    <template v-for="item2 in item.children" :key="item2.name">
                    <el-menu-item :index="item2.name" @click="handleSelect(item.path,item.name)">
                        <span>{{item2.title}}</span>
                    </el-menu-item>
                    </template>
                </el-submenu>
                <!--一级菜单使用v-else在不在submenu中为非下拉状态-->
                <el-menu-item :index="item.name" :key="item.name" v-else @click="handleSelect(item.path,item.name)">
                    <i class="iconfont i_icon" :class="item.meta.icon"></i>
                    <template #title>
                    <span>{{item.meta.title}}</span>
                    </template>
                </el-menu-item>
                </template>
                </el-menu>
            </el-aside>
            <el-main class="container-right">
                <router-view></router-view>
            </el-main>
        </el-container>
        <FooterNew></FooterNew>
    </div>
   <!-- router-view 当你的路由path 与访问的地址相符时，会将指定的组件替换该 router-view  一组router-view代表一个组件-->
  </div>
</template>
<script>
import Header from '@/components/Header.vue'
import FooterNew from '@/components/FooterNew.vue'
import {getUserRightFun} from '@/http/basicsApi'
import {ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
export default {
    components:{
        Header,
        FooterNew,
    },
    setup() {
        let route=useRoute();
        let router=useRouter();
        let isRight=ref(false);
        let selMenu=ref();
        let menuList=ref();
        //判断是否有权限访问此页面
        getUserRightFun(route.name,0).then(res=>{
            isRight.value=res;
            if(isRight.value){
                menuList.value=router.getRoutes().find(t=>t.name.toLowerCase()=='usercenter').children
                .sort(function (a, b) {//升序
                        if(a.meta.sort==null || b.meta.sort==null) return -1
                        return a.meta.sort - b.meta.sort;
                    });
                selMenu.value=route.name;//选中菜单
            }
        });
        //功能菜单的选择事件
        const handleSelect=async (path,name)=>{// eslint-disable-line no-unused-vars
          
            if(await getUserRightFun(name,0)){
                router.push(path)
            }
        }
        return{
            isRight,
            menuList,
            selMenu,
            handleSelect
        }
    },
}
</script>
<style lang='scss' scoped>
.container {
    position: relative;
    background: transparent;
    margin: 20px auto 0;
}
.container .container-left{
    background: #fff;
    margin-right: 10px;
    padding: 10px 0;
}
.container .container-right{
    padding: 0;
}
</style>